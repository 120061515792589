/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
    color: #e6af07;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    color: #e6af07;
    font-family: 'AvenirNext-Medium', sans-serif;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  /* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
  @font-face {
    font-family: 'AvenirNext-Medium';
    src: url('fonts/AvenirNext-Medium.ttf');
  }
  
  .font-avenirNextMedium {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  }
  @media (min-resolution: 120dpi) {
    .font-avenirNextMedium {
      font-family: 'AvenirNext-Medium', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    }
  }
  
  /* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
  @font-face {
    font-family: 'AvenirNext-Regular';
    src: url('fonts/AvenirNext-Regular.ttf');
  }
  
  .font-avenirNextRegular {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  }
  @media (min-resolution: 120dpi) {
    .font-avenirNextRegular {
      font-family: 'AvenirNext-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    }
  }
  
  .App { /* Base font style */
    font-family: 'AvenirNext-Regular', sans-serif;
    font-size: 15.1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.8500);
    background-color: #f6f6f6;
  }
  
  .App .baseFont * { /* Base font style override for UI framework components */
    font-family: 'AvenirNext-Regular', sans-serif;
    font-size: 15.1px;
  }
  
  .App .appBg {
    background-color: #f6f6f6;
  }
  
  .App .cardBg {
    background-color: white;
  }
  
  .App .baseTextBg { /* Base text color as background */
    background-color: rgba(0, 0, 0, 0.8500);
  }
  
  .App .secondaryTextColor {
    color: rgba(0, 0, 0, 0.5000);
  }
  
  .App .secondaryTextBg {
    background-color: rgba(0, 0, 0, 0.5000);
  }
  
  .App .primaryTextColor {
    color: #e6af07;
  }
  
  .App .primaryBg {
    background-color: #e6af07;
  }
  
  .App .highlightTextColor {
    color: #0093d5;
  }
  
  .App .highlightBg {
    background-color: #0093d5;
  }
  
  /* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
  .mui-btn {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 0;
  }
  .mui-btn--primary {
      background-color: #e6af07;
  }
  .mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
      background-color: #e9b51c;
  }
  .mui-btn--accent {
      background-color: #0093d5;
  }
  .mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
      background-color: #069fda;
  }
  .mui-textfield {  /* Reset UI framework styling that affects layout */
      padding-top: 0;
      margin-bottom: 0;
  }
  .mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
      margin-top: 0;
      margin-bottom: 0;
  }
  .mui-select {  /* Reset UI framework styling that affects layout */
      padding-top: 0;
      margin-bottom: 0;
  }
  .mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
      margin-left: 0;
  }
  .mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
      z-index: auto;
  }
  
  @keyframes fadeIn {
      0%   { opacity: 0.0; }
      100% { opacity: 1.0; }
  }
  @keyframes slideIn_right {
      0%   { transform: translateX(100%); }
      100% { transform: translateX(0); }
  }
  @keyframes slideIn_bottom {
      0%   { transform: translateY(100%); }
      100% { transform: translateY(0); }
  }
  
  /* App-wide text styles specified in React Studio */
  .App .actionFont {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 14.2px;
    font-weight: 600;
  }
  @media (min-resolution: 120dpi) {
    .App .actionFont {
      font-family: 'AvenirNext-Regular', sans-serif;
    }
  }
  .App .actionFont * {
    font-size: 14.2px;
  }
  .App .headlineFont {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 19.4px;
    font-weight: 600;
  }
  @media (min-resolution: 120dpi) {
    .App .headlineFont {
      font-family: 'AvenirNext-Medium', sans-serif;
    }
  }
  .App .headlineFont * {
    font-size: 19.4px;
  }
  .App .navBar {
    background-color: #e6af07;
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 25px;
  }
  
  .App .navBar a {
    color: black;
  }
  
  @media (min-resolution: 120dpi) {
    .App .navBar {
      font-family: 'AvenirNext-Medium', sans-serif;
    }
  }
  
  .App .navBar .title {
    position: absolute;
    width: 100%;
    text-align: center;
    padding: 4px;
    margin-top: 14px;
  }
  
  .App .navBar .backBtn {
    position: absolute;
    padding: 4px;
    margin-left: 14px;
    margin-top: 14px;
    cursor: pointer;
  }
  
  @media (min-width: 320px) {
    .mui-container {
      max-width: 320px;
    }
  }
  @media (min-width: 568px) {
    .mui-container {
      max-width: 568px;
    }
  }
  @media (min-width: 768px) {
    .mui-container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .mui-container {
      max-width: 1024px;
    }
  }
  
  .AppScreen {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    width: 100%;
  }
  
  .App .background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .App .layoutFlow {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    float: left;
  }
  .App .layoutFlow:after {
    content: "";
    display: table;
    clear: both;
  }
  .App .layoutFlow .flowRow {
    position: relative;
    width: 100%;
  }
  
  .App .screenFgContainer {
    /* Items in the foreground group within a screen are placed with fixed positioning */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
  }
  .App .foreground {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .App .compContent {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
  
  .App ul {
    margin: 0;
    padding: 0;
  }
  .App li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
  }
  .App .hasNestedComps {
    position: relative;
  }
  .App .hasNestedComps:after {
    content: "";
    display: table;
    clear: both;
  }
  .App .hasNestedComps .background {
    position: absolute;
  }
  .App .hasNestedComps .layoutFlow {
    position: relative;
  }
  .App .hasNestedComps .foreground {
    position: absolute;
  }
  .App .bottomAlignedContent {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  /* Narrowest size for component's responsive layout */
    .User {
      min-height: 333px;
      overflow: hidden;
    }
    .User > .background > .containerMinHeight {
      min-height: 333px;
    }
  @media (min-width: 568px) {
    .User {
      min-height: 333px;
      overflow: hidden;
    }
    .User > .background > .containerMinHeight {
      min-height: 333px;
    }
  }
  @media (min-width: 768px) {
    .User {
      min-height: 331px;
      overflow: hidden;
    }
    .User > .background > .containerMinHeight {
      min-height: 331px;
    }
  }
  @media (min-width: 1024px) {
    .User {
      min-height: 331px;
      overflow: hidden;
    }
    .User > .background > .containerMinHeight {
      min-height: 331px;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .User .elCard {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  @media (min-width: 568px) {
    .User .elCard {
      left: 0px;
      right: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .User .elCard {
      left: 0px;
      right: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .User .elCard {
      left: 0px;
      right: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  
  /* Narrowest size for component's responsive layout */
    .Users {
      min-height: 639px;
    }
    .Users > .background > .containerMinHeight {
      min-height: 639px;
    }
  @media (min-width: 568px) {
    .Users {
      min-height: 639px;
    }
    .Users > .background > .containerMinHeight {
      min-height: 639px;
    }
  }
  @media (min-width: 768px) {
    .Users {
      min-height: 635px;
    }
    .Users > .background > .containerMinHeight {
      min-height: 635px;
    }
  }
  @media (min-width: 1024px) {
    .Users {
      min-height: 635px;
    }
    .Users > .background > .containerMinHeight {
      min-height: 635px;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .Users .state0_elCard561616 {
      position: absolute;
      left: 0px;
      right: 0px;
      top: -11px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  @media (min-width: 568px) {
    .Users .state0_elCard561616 {
      left: 0px;
      right: 0px;
      top: -11px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .Users .state0_elCard561616 {
      left: 0px;
      right: 0px;
      top: -11px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .Users .state0_elCard561616 {
      left: 0px;
      right: 0px;
      top: -11px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .Users .state0_elUsers_title_card598186 {
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: 0px;
    }
    .Users .state0_elUsers_title_card598186 > * { 
    width: 238px;
    }
    
  @media (min-width: 568px) {
    .Users .state0_elUsers_title_card598186 {
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: 0px;
    }
    .Users .state0_elUsers_title_card598186 > * { 
    width: 238px;
    }
    
  }
  @media (min-width: 768px) {
    .Users .state0_elUsers_title_card598186 {
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: 0px;
    }
    .Users .state0_elUsers_title_card598186 > * { 
    width: 236px;
    }
    
  }
  @media (min-width: 1024px) {
    .Users .state0_elUsers_title_card598186 {
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: 0px;
    }
    .Users .state0_elUsers_title_card598186 > * { 
    width: 236px;
    }
    
  }
  
  .circularProgressIndicator {
      position: relative;
      width: 50px;
      height: 50px;
      display: none;
  }
  .circularProgressIndicator-active {
      animation: rotate 2s linear infinite;
      display: inline;
  }
  .circularProgress-animatedPath {
      stroke-dasharray: 1,200;
      stroke-dashoffset: 0;
      animation: circularProgress-dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
  }
  @keyframes rotate{
      100%{
      transform: rotate(360deg);
      }
  }
  @keyframes circularProgress-dash{
      0%{
          stroke-dasharray: 1,200;
          stroke-dashoffset: 0;
      }
      50%{
          stroke-dasharray: 89,200;
          stroke-dashoffset: -35;
      }
      100%{
          stroke-dasharray: 89,200;
          stroke-dashoffset: -124;
      }
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .Users .state0_elProgress {
      position: absolute;
      left: calc(50.0% + 0px - 24px);
      top: 320px;
      width: 48px;
      height: 48px;
    }
  @media (min-width: 568px) {
    .Users .state0_elProgress {
      left: calc(50.0% + 0px - 24px);
      top: 320px;
      width: 48px;
      height: 48px;
    }
  }
  @media (min-width: 768px) {
    .Users .state0_elProgress {
      left: calc(50.0% + 0px - 24px);
      top: 318px;
      width: 47px;
      height: 47px;
    }
  }
  @media (min-width: 1024px) {
    .Users .state0_elProgress {
      left: calc(50.0% + 0px - 24px);
      top: 318px;
      width: 47px;
      height: 47px;
    }
  }
  
  /* Narrowest size for component's responsive layout */
    .Users {
      min-height: 639px;
    }
    .Users > .background > .containerMinHeight {
      min-height: 639px;
    }
  @media (min-width: 568px) {
    .Users {
      min-height: 639px;
    }
    .Users > .background > .containerMinHeight {
      min-height: 639px;
    }
  }
  @media (min-width: 768px) {
    .Users {
      min-height: 635px;
    }
    .Users > .background > .containerMinHeight {
      min-height: 635px;
    }
  }
  @media (min-width: 1024px) {
    .Users {
      min-height: 635px;
    }
    .Users > .background > .containerMinHeight {
      min-height: 635px;
    }
  }
  
  /* Narrowest size for this element's responsive layout */
    .Users .state1_elCard561616 {
      position: absolute;
      left: 0px;
      right: 0px;
      top: -11px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  @media (min-width: 568px) {
    .Users .state1_elCard561616 {
      left: 0px;
      right: 0px;
      top: -11px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .Users .state1_elCard561616 {
      left: 0px;
      right: 0px;
      top: -11px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .Users .state1_elCard561616 {
      left: 0px;
      right: 0px;
      top: -11px;
      height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .Users .state1_elUsers_title_card598186 {
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: 0px;
    }
    .Users .state1_elUsers_title_card598186 > * { 
    width: 238px;
    }
    
  @media (min-width: 568px) {
    .Users .state1_elUsers_title_card598186 {
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: 0px;
    }
    .Users .state1_elUsers_title_card598186 > * { 
    width: 238px;
    }
    
  }
  @media (min-width: 768px) {
    .Users .state1_elUsers_title_card598186 {
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: 0px;
    }
    .Users .state1_elUsers_title_card598186 > * { 
    width: 236px;
    }
    
  }
  @media (min-width: 1024px) {
    .Users .state1_elUsers_title_card598186 {
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: 0px;
    }
    .Users .state1_elUsers_title_card598186 > * { 
    width: 236px;
    }
    
  }
  
    .Users .state1_elList li {
      margin-bottom: 9px;
    }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .Users .state1_elList {
      position: relative;
      margin-top: 0px;
      margin-left: 5.0%;
      margin-right: 5.0%;
      width: calc(90.0% - 0px);
    }
    .Users .state1_elList > * { 
      width: 100%;
    height: 320px;
    }
    
  @media (min-width: 568px) {
    .Users .state1_elList {
      position: relative;
      margin-top: 0px;
      margin-left: 5.0%;
      margin-right: 5.0%;
      width: calc(90.0% - 0px);
    }
    .Users .state1_elList > * { 
      width: 100%;
    height: 320px;
    }
    
  }
  @media (min-width: 768px) {
    .Users .state1_elList {
      position: relative;
      margin-top: 0px;
      margin-left: 5.0%;
      margin-right: 5.0%;
      width: calc(90.0% - 0px);
    }
    .Users .state1_elList > * { 
      width: 100%;
    height: 318px;
    }
    
  }
  @media (min-width: 1024px) {
    .Users .state1_elList {
      position: relative;
      margin-top: 0px;
      margin-left: 5.0%;
      margin-right: 5.0%;
      width: calc(90.0% - 0px);
    }
    .Users .state1_elList > * { 
      width: 100%;
    height: 318px;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
  .HomeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;
    min-height: 100%;
  }
  @media (min-width: 568px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;
    min-height: 100%;
  }
  }
  @media (min-width: 768px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;
    min-height: 100%;
  }
  }
  @media (min-width: 1024px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;
    min-height: 100%;
  }
  }
  
  .HomeScreen .elColumns:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .HomeScreen .elColumns .col0 {
    float: left;
    position: relative;
  }
  
  @media (min-width: 1024px) {
  .HomeScreen .elColumns .col0 {
    width: calc(50.0% - 9.0px*(1/2));
    overflow: hidden;
  }
  
  .HomeScreen .elColumns .col0 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .HomeScreen .elColumns .col1:after {
    content: "";
    display: table;
    clear: both;
  }
  
  }
  
  @media (max-width: 1023px) {
  .HomeScreen .elColumns .col0 {
    width: calc(100.0% - 0.0px*(0/1));
    overflow: hidden;
  }
  
  .HomeScreen .elColumns .col0 {
    margin-bottom: 9.0px;
  }
  .HomeScreen .elColumns .col1:after { }
  .HomeScreen .elColumns .col0:after {
    content: "";
    display: table;
    clear: both;
  }
  
  }
  
  @media (max-width: 767px) {
  .HomeScreen .elColumns .col0 {
    width: calc(100.0% - 0.0px*(0/1));
    overflow: hidden;
  }
  
  .HomeScreen .elColumns .col0 { margin-right: 0; margin-bottom: 0; }
  .HomeScreen .elColumns .col0 {
    margin-bottom: 9.1px;
  }
  .HomeScreen .elColumns .col1:after { }
  .HomeScreen .elColumns .col0:after { }
  .HomeScreen .elColumns .col0:after {
    content: "";
    display: table;
    clear: both;
  }
  
  }
  
  @media (max-width: 567px) {
  .HomeScreen .elColumns .col0 {
    width: calc(100.0% - 0.0px*(0/1));
    overflow: hidden;
  }
  
  .HomeScreen .elColumns .col0 { margin-right: 0; margin-bottom: 0; }
  .HomeScreen .elColumns .col0 { margin-right: 0; margin-bottom: 0; }
  .HomeScreen .elColumns .col0 {
    margin-bottom: 9.1px;
  }
  .HomeScreen .elColumns .col1:after { }
  .HomeScreen .elColumns .col0:after { }
  .HomeScreen .elColumns .col0:after { }
  .HomeScreen .elColumns .col0:after {
    content: "";
    display: table;
    clear: both;
  }
  
  }
  
  .HomeScreen .elColumns:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .HomeScreen .elColumns .col1 {
    float: left;
    position: relative;
  }
  
  @media (min-width: 1024px) {
  .HomeScreen .elColumns .col1 {
    width: calc(50.0% - 9.0px*(1/2));
    overflow: hidden;
  }
  
  .HomeScreen .elColumns .col0 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .HomeScreen .elColumns .col1:after {
    content: "";
    display: table;
    clear: both;
  }
  
  }
  
  @media (max-width: 1023px) {
  .HomeScreen .elColumns .col1 {
    width: calc(100.0% - 0.0px*(0/1));
    overflow: hidden;
  }
  
  .HomeScreen .elColumns .col0 {
    margin-bottom: 9.0px;
  }
  .HomeScreen .elColumns .col1:after { }
  .HomeScreen .elColumns .col0:after {
    content: "";
    display: table;
    clear: both;
  }
  
  }
  
  @media (max-width: 767px) {
  .HomeScreen .elColumns .col1 {
    width: calc(100.0% - 0.0px*(0/1));
    overflow: hidden;
  }
  
  .HomeScreen .elColumns .col0 { margin-right: 0; margin-bottom: 0; }
  .HomeScreen .elColumns .col0 {
    margin-bottom: 9.1px;
  }
  .HomeScreen .elColumns .col1:after { }
  .HomeScreen .elColumns .col0:after { }
  .HomeScreen .elColumns .col0:after {
    content: "";
    display: table;
    clear: both;
  }
  
  }
  
  @media (max-width: 567px) {
  .HomeScreen .elColumns .col1 {
    width: calc(100.0% - 0.0px*(0/1));
    overflow: hidden;
  }
  
  .HomeScreen .elColumns .col0 { margin-right: 0; margin-bottom: 0; }
  .HomeScreen .elColumns .col0 { margin-right: 0; margin-bottom: 0; }
  .HomeScreen .elColumns .col0 {
    margin-bottom: 9.1px;
  }
  .HomeScreen .elColumns .col1:after { }
  .HomeScreen .elColumns .col0:after { }
  .HomeScreen .elColumns .col0:after { }
  .HomeScreen .elColumns .col0:after {
    content: "";
    display: table;
    clear: both;
  }
  
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 66px;
    margin-left: 10.0%;
    margin-right: 10.0%;
    width: calc(80.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
  @media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 66px;
    margin-left: 10.0%;
    margin-right: 10.0%;
    width: calc(80.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
  }
  @media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 66px;
    margin-left: 10.0%;
    margin-right: 10.0%;
    width: calc(80.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  height: 226px;
  }
  
  }
  @media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 66px;
    margin-left: 10.0%;
    margin-right: 10.0%;
    width: calc(80.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  height: 226px;
  }
  
  }
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .background > .elBackground {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }
  @media (min-width: 568px) {
    .LoginScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .LoginScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .LoginScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .layoutFlow > .elLOGOnexusbar {
      position: relative;
      margin-top: 0px;
      width: 286px;
      margin-left: calc(50.0% - 143px);
    }
    .LoginScreen > .layoutFlow > .elLOGOnexusbar > * { 
    width: 286px;
    height: 228px;
    }
    
  @media (min-width: 568px) {
    .LoginScreen > .layoutFlow > .elLOGOnexusbar {
      position: relative;
      margin-top: 0px;
      width: 286px;
      margin-left: calc(50.0% - 143px);
    }
    .LoginScreen > .layoutFlow > .elLOGOnexusbar > * { 
    width: 286px;
    height: 228px;
    }
    
  }
  @media (min-width: 768px) {
    .LoginScreen > .layoutFlow > .elLOGOnexusbar {
      position: relative;
      margin-top: 0px;
      width: 284px;
      margin-left: calc(50.0% - 142px);
    }
    .LoginScreen > .layoutFlow > .elLOGOnexusbar > * { 
    width: 284px;
    height: 226px;
    }
    
  }
  @media (min-width: 1024px) {
    .LoginScreen > .layoutFlow > .elLOGOnexusbar {
      position: relative;
      margin-top: 0px;
      width: 284px;
      margin-left: calc(50.0% - 142px);
    }
    .LoginScreen > .layoutFlow > .elLOGOnexusbar > * { 
    width: 284px;
    height: 226px;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .layoutFlow > .elUsername {
      position: relative;
      margin-top: 55px;
      margin-left: 16.0%;
      margin-right: 16.0%;
      width: calc(68.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elUsername > * { 
      width: 100%;
    height: 34px;
    }
    
  @media (min-width: 568px) {
    .LoginScreen > .layoutFlow > .elUsername {
      position: relative;
      margin-top: 30px;
      margin-left: 16.0%;
      margin-right: 16.0%;
      width: calc(68.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elUsername > * { 
      width: 100%;
    height: 34px;
    }
    
  }
  @media (min-width: 768px) {
    .LoginScreen > .layoutFlow > .elUsername {
      position: relative;
      margin-top: 100px;
      margin-left: 16.0%;
      margin-right: 16.0%;
      width: calc(68.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elUsername > * { 
      width: 100%;
    height: 34px;
    }
    
  }
  @media (min-width: 1024px) {
    .LoginScreen > .layoutFlow > .elUsername {
      position: relative;
      margin-top: 75px;
      margin-left: 16.0%;
      margin-right: 16.0%;
      width: calc(68.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elUsername > * { 
      width: 100%;
    height: 34px;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .layoutFlow > .elPassword {
      position: relative;
      margin-top: 16px;
      margin-left: 16.0%;
      margin-right: 16.0%;
      width: calc(68.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elPassword > * { 
      width: 100%;
    height: 34px;
    }
    
  @media (min-width: 568px) {
    .LoginScreen > .layoutFlow > .elPassword {
      position: relative;
      margin-top: 9px;
      margin-left: 16.0%;
      margin-right: 16.0%;
      width: calc(68.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elPassword > * { 
      width: 100%;
    height: 34px;
    }
    
  }
  @media (min-width: 768px) {
    .LoginScreen > .layoutFlow > .elPassword {
      position: relative;
      margin-top: 30px;
      margin-left: 16.0%;
      margin-right: 16.0%;
      width: calc(68.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elPassword > * { 
      width: 100%;
    height: 34px;
    }
    
  }
  @media (min-width: 1024px) {
    .LoginScreen > .layoutFlow > .elPassword {
      position: relative;
      margin-top: 22px;
      margin-left: 16.0%;
      margin-right: 16.0%;
      width: calc(68.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elPassword > * { 
      width: 100%;
    height: 34px;
    }
    
  }
  
  .LoginScreen .elLogin button {
    border: 0.0px solid transparent;
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .layoutFlow > .elLogin {
      line-height: 35px;
      position: relative;
      margin-top: 82px;
      width: 145px;
      margin-left: calc(50.0% - 72px);
    }
    .LoginScreen > .layoutFlow > .elLogin > * { 
    width: 145px;
    height: 35px;
    }
    
  @media (min-width: 568px) {
    .LoginScreen > .layoutFlow > .elLogin {
      line-height: 35px;
      position: relative;
      margin-top: 45px;
      width: 145px;
      margin-left: calc(50.0% - 72px);
    }
    .LoginScreen > .layoutFlow > .elLogin > * { 
    width: 145px;
    height: 35px;
    }
    
  }
  @media (min-width: 768px) {
    .LoginScreen > .layoutFlow > .elLogin {
      line-height: 35px;
      position: relative;
      margin-top: 150px;
      width: 144px;
      margin-left: calc(50.0% - 72px);
    }
    .LoginScreen > .layoutFlow > .elLogin > * { 
    width: 144px;
    height: 35px;
    }
    
  }
  @media (min-width: 1024px) {
    .LoginScreen > .layoutFlow > .elLogin {
      line-height: 35px;
      position: relative;
      margin-top: 112px;
      width: 144px;
      margin-left: calc(50.0% - 72px);
    }
    .LoginScreen > .layoutFlow > .elLogin > * { 
    width: 144px;
    height: 35px;
    }
    
  }
  
  screenStyleTitle {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 68px;
    margin-left: calc(50.0% - 34px);
  }
  
  
  /* This component has a fixed-size layout */
  .CustomListCardbox {
    min-height: 113px;
    width: 100%; /* This component was designed using a width of 181px */
  }
  .CustomListCardbox > .background > .containerMinHeight {
    min-height: 113px;
  }
  
  
  .CustomListCardbox .elTextBlock {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .CustomListCardbox .elTextBlock > * { 
    width: 100%;
  height: 23px;
  }
  
  
  
  .CustomListCardbox .elBackground {
    margin-top: 9px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .CustomListCardbox .elBackground > * {
  }
  
  /* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextBlock {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 68px;
    margin-left: calc(50.0% - 34px);
  }
  .HomeScreen > .layoutFlow > .elTextBlock > * { 
  width: 68px;
  height: 34px;
  }
  
  @media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextBlock {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 68px;
    margin-left: calc(50.0% - 34px);
  }
  .HomeScreen > .layoutFlow > .elTextBlock > * { 
  width: 68px;
  height: 34px;
  }
  
  }
  @media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextBlock {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 68px;
    margin-left: calc(50.0% - 34px);
  }
  .HomeScreen > .layoutFlow > .elTextBlock > * { 
  width: 68px;
  height: 34px;
  }
  
  }
  @media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextBlock {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 68px;
    margin-left: calc(50.0% - 34px);
  }
  .HomeScreen > .layoutFlow > .elTextBlock > * { 
  width: 68px;
  height: 34px;
  }
  
  }
  
  /* Narrowest size for component's responsive layout */
  .CustomItemCardbox {
    min-height: 24px;
    overflow: hidden;
  }
  .CustomItemCardbox > .background > .containerMinHeight {
    min-height: 24px;
  }
  @media (min-width: 568px) {
  .CustomItemCardbox {
    min-height: 24px;
    overflow: hidden;
  }
  .CustomItemCardbox > .background > .containerMinHeight {
    min-height: 24px;
  }
  }
  @media (min-width: 768px) {
  .CustomItemCardbox {
    min-height: 24px;
    overflow: hidden;
  }
  .CustomItemCardbox > .background > .containerMinHeight {
    min-height: 24px;
  }
  }
  @media (min-width: 1024px) {
  .CustomItemCardbox {
    min-height: 24px;
    overflow: hidden;
  }
  .CustomItemCardbox > .background > .containerMinHeight {
    min-height: 24px;
  }
  }
  
  
  /* Narrowest size for this element's responsive layout */
  .CustomItemCardbox .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    height: auto;
  }
  @media (min-width: 568px) {
  .CustomItemCardbox .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    height: auto;
  }
  }
  @media (min-width: 768px) {
  .CustomItemCardbox .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    height: 100%;
  }
  }
  @media (min-width: 1024px) {
  .CustomItemCardbox .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    height: 100%;
  }
  }
  
  
  /* Narrowest size for this element's responsive layout */
  .CustomItemCardbox .elLineBottom {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 23px;
    width: 100%;
    height: 1px;
  }
  @media (min-width: 568px) {
  .CustomItemCardbox .elLineBottom {
    left: 0px;
    right: 0px;
    top: 23px;
    width: 100%;
    height: 1px;
  }
  }
  @media (min-width: 768px) {˘
  .CustomItemCardbox .elLineBottom {
    left: 0px;
    right: 0px;
    top: 23px;
    width: 100%;
    height: 1px;
  }
  }
  @media (min-width: 1024px) {
  .CustomItemCardbox .elLineBottom {
    left: 0px;
    right: 0px;
    top: 23px;
    width: 100%;
    height: 1px;
  }
  }